* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Avenir Next';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#main-content{
  background-color: #fff;
}

.scan h2 {
  padding-bottom: 10vh;
  color: #282c34;
  animation: blinkBorder 1.2s linear infinite;
}

.qrScanner {
  animation: blinkBorder 1.2s linear infinite;
}


@keyframes blinkBorder {
  0%, 100% {
    opacity: 1;
  }
  10%, 90% {
    opacity: 0.97;
  }
  20%, 80% {
    opacity: 0.94;
  }
  30%, 70% {
    opacity: 0.91;
  }
  40%, 60% {
    opacity: 0.88;
  }
  50% {
    opacity: 0.85;
  }
}

.fade-in-element {
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}